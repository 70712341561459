import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Fader from '../../Loaders/Fader';
import PieChartComponent from '../../Charts/PieChart/PieChart';

import useOutsideClick from '../../../../hooks/useOutsideClick';
import { getSentimentSources } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';

import './SentimentSource.scss';

const initialSentimentState = {
  positive: 0,
  neutral: 0,
  negative: 0,
};

const SentimentSource = ({
  handleFaqPage,
  loading,
  setLoading,
  activeTopicId,
  userDataState,
  setOverallSentimentNegative,
  setOverallSentimentPositive,
}) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [faq, setFaq] = useState(false);
  const [currentSource, setCurrentSource] = useState('all');
  const [sentimentBySource, setSentimentBySource] = useState({
    forums: initialSentimentState,
    twitter: initialSentimentState,
    news: initialSentimentState,
    reddit: initialSentimentState,
    all: initialSentimentState,
  });

  const faqRef = useRef();
  const faqIconRef = useRef();

  useOutsideClick(faqRef, faqIconRef, faq, () => setFaq(false));

  const toggleFaq = () => {
    setFaq((prevState) => !prevState);
  };

  const showFullFaq = () => {
    handleFaqPage();
    setFaq(false);
  };

  const handleChangeSource = (source) => {
    setCurrentSource(source);
  };

  const resetSentimentState = () => {
    setOverallSentimentNegative(0);
    setOverallSentimentPositive(0);
    setCurrentSource('all');
    setSentimentBySource({
      all: initialSentimentState,
      forums: initialSentimentState,
      twitter: initialSentimentState,
      news: initialSentimentState,
      reddit: initialSentimentState,
    });
  };

  const handleGetSentimentSources = async (id, userId) => {
    setLoading(true);
    resetSentimentState();

    const res = await getSentimentSources(config, id, userId);
    if (res.status === 'success') {
      const sourceData = res.data;
      setSentimentBySource({
        all: sourceData.all,
        forums: sourceData.forums,
        twitter: sourceData.twitter,
        news: sourceData.news,
        reddit: sourceData.reddit,
      });
      setOverallSentimentNegative(sourceData.all.negative_overall);
      setOverallSentimentPositive(sourceData.all.positive_overall);
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Sentiment Source data');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetSentimentSources(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  const currentSentimentValues = useMemo(() => sentimentBySource[currentSource], [sentimentBySource, currentSource]);

  return (
    <div className="sentiment-source chart-component cc-sentiment-charts">
      <div ref={faqIconRef} className="info-icon" onClick={toggleFaq}>
        i
      </div>
      {faq && (
        <div className="info-box" ref={faqRef}>
          <div className="ii-copy">
            <div className="iic-exp">
              This feature analyzes the sentiment (positive, negative, neutral) of messages from different sources or
              platforms (e.g., Twitter, Facebook, blogs).
            </div>
            <div className="iic-sub">How to Use</div>
            <ol>
              <li>Navigate to the 'Sentiment by Source' section on the dashboard.</li>
              <li>View the sentiment breakdown by source, displayed in a pie chart or bar graph.</li>
              <li>Click on a source to drill down into specific sentiment details for that platform</li>
            </ol>
            <span className="link" onClick={showFullFaq}>
              See All
            </span>
          </div>
        </div>
      )}
      <div className="chart-title">Sentiment By Source</div>
      {!loading ? (
        <>
          <div className="sentiment-charts">
            <PieChartComponent
              positive={currentSentimentValues.positive}
              neutral={currentSentimentValues.neutral}
              negative={currentSentimentValues.negative}
            />
          </div>
          <div className="sentiment-source-bar">
            {['forums', 'twitter', 'news', 'reddit', 'all'].map((source) => {
              let displayName = source.charAt(0).toUpperCase() + source.slice(1);
              if (source === 'twitter') {
                displayName = 'X (Twitter)';
              }
              return (
                <div
                  key={source}
                  className={currentSource === source ? 'source-item selected' : 'source-item'}
                  onClick={() => handleChangeSource(source)}
                >
                  {displayName}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="sentiment-charts">
          <Fader />
        </div>
      )}
    </div>
  );
};

export default SentimentSource;
