import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';

import WardsMap from '../../components/WardsMap/WardsMap';
import Fader from '../../components/Loaders/Fader';
import Tooltip from '../../components/Tooltip/Tooltip';

import './Map.scss';

const Map = () => {
  const config = useSelector((state) => state.config.config);
  const cdnUrl = config.api.cdn;

  const [mapType, setMapType] = useState('');
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);

  const { district } = useOutletContext();

  useEffect(() => {
    if (district) {
      if (district.district.startsWith('Brampton')) {
        setMapType('wards');
      } else if (district.atlasMapJpgKey) {
        setMapType('atlas');
      } else if (district.detailedMapJpgKey) {
        setMapType('detailed');
      }
    }
  }, [district]);

  const handleMapChange = (type) => {
    setLoading(true);
    setMapType(type);
  };

  const handleOpenImg = () => {
    const pdfUrl = `${cdnUrl}/${mapType === 'atlas' ? district.atlasMapPdfKey : district.detailedMapPdfKey}`;
    window.open(pdfUrl, '_blank');
  };

  const handleMouseMove = (event) => {
    setTooltipPos({ x: event.clientX, y: event.clientY });
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="platform-frame">
      <div className="elections map">
        {district ? (
          <>
            <div className="pfr-header">
              <div className="dashboard-title">{`Electoral ${district.district} Map`}</div>
            </div>
            <ul className="map__tabs">
              {district.district.startsWith('Brampton') && (
                <li className="map__tab">
                  <button
                    type="button"
                    className={`map__btn ${mapType === 'wards' ? 'active' : ''}`}
                    onClick={() => setMapType('wards')}
                  >
                    Wards Map
                  </button>
                </li>
              )}
              {district.atlasMapJpgKey && (
                <li className="map__tab">
                  <button
                    type="button"
                    className={`map__btn ${mapType === 'atlas' ? 'active' : ''}`}
                    onClick={() => handleMapChange('atlas')}
                  >
                    Atlas Map
                  </button>
                </li>
              )}
              {district.detailedMapJpgKey && (
                <li className="map__tab">
                  <button
                    type="button"
                    className={`map__btn ${mapType === 'detailed' ? 'active' : ''}`}
                    onClick={() => handleMapChange('detailed')}
                  >
                    Overview Detailed Wall Map
                  </button>
                </li>
              )}
            </ul>
            <div className="chart-component">
              {mapType === 'wards' ? (
                <WardsMap />
              ) : mapType !== '' ? (
                <>
                  {loading && <Fader />}
                  <img
                    className="map__image"
                    src={`${cdnUrl}/${mapType === 'atlas' ? district.atlasMapJpgKey : district.detailedMapJpgKey}`}
                    alt="Electoral map"
                    onClick={handleOpenImg}
                    onMouseMove={handleMouseMove}
                    onLoad={handleImageLoad}
                    style={{ display: loading ? 'none' : 'block' }}
                  />
                  <Tooltip content="Click to open the PDF" x={tooltipPos.x} y={tooltipPos.y} />
                </>
              ) : (
                <Fader />
              )}
            </div>
          </>
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default Map;
